import * as React from "react"
import { css } from "@emotion/react"

const Spacer = () => {
  return (
    <div
      css={css`
        background: transparent;
        height: 3rem;
      `}
    ></div>
  )
}

export default Spacer
