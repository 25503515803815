import * as React from "react"
import {css} from "@emotion/react"
import GreenHR from "../hr";

const SubHeading = ({children}) => {
  return (
    <>
      <div css={css`display: grid; grid-template-columns: auto; width: fit-content; `}>
    <h2 css={css`text-align: left; margin-bottom: 8px; `}>{children}</h2>
    <GreenHR />
      </div>
    </>
  )
}

export default SubHeading