import * as React from "react"
import { css } from "@emotion/react"

const ColoredWrapper = ({ children }) => {
  return (
    <div
      css={css`
        background: var(--grey);
        color: var(--background);
        padding: 2rem 0 2rem 0;
        margin: 2rem auto 0 auto;
        border: none;
      `}
    >
      {children}
    </div>
  )
}

export default ColoredWrapper
