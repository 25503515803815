import * as React from "react"
import {css} from "@emotion/react"

const SpanBold = ({children}) => {
  return (
    <div>
      <span css={css` font-weight: bold; margin-bottom: 1rem; `}>{children}</span>
    </div>
    )
  }

export default SpanBold
