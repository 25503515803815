import * as React from "react"
import { css } from "@emotion/react"
import styled from "@emotion/styled"
import { StaticImage } from "gatsby-plugin-image"
import Layout from "../../components/layout"
import Seo from "../../components/seo"

import ColoredWrapper from "../../components/wrappers/colorWrapper"
import ContentWrapper from "../../components/wrappers/contentWrapper"
import Spacer from "../../components/wrappers/spacer"
import Heading from "../../components/typography/heading";
import SubHeading from "../../components/typography/subHeading";
import SpanBold from "../../components/typography/SpanBold";

const HeroImage = styled.div`
  margin: 0 auto;
  width: 100%;
  max-height: 600px;
  overflow: hidden;
`

const QuickbooksProAdvisorPage = () => {
  return (
    <Layout>
      <Seo
        title="Certified Quickbooks Online ProAdviser"
        description="Helping small businesses with record keeping, bank and credit card reconciliations, tax remittance, and monthly statements"
      />
      <HeroImage>
        <StaticImage
          src="../../images/hero/hero_splash_quickbooks.jpg"
          alt="quickbooks pro advisor hero image"
          objectPosition="50% 50%"
        />
      </HeroImage>

      <ContentWrapper>
        <Heading>
          Certified Quickbooks Online ProAdviser
        </Heading>
        <p
          css={css`
            margin-top: var(--marginTop);
          `}
        >
          I am a certified{" "}
          <a
            href="https://quickbooks.intuit.com/ca/find-an-accountant/"
            title="Certified Quickbooks Online ProAdviser"
            canonical="https://labellebookkeeping.com/services/quickbooks-online-proadvisor/"
          >
            Quickbooks Online ProAdviser
          </a>{" "}
          who provides general bookkeeping services to business owners like
          yourself who want to simplify their work and make their financial
          records easier to manage and understand. I have my Bookkeeping for
          Small Businesses certificate and years of experience working as a
          general bookkeeper in various industries. I always make bookkeeping
          records more efficient and less time-consuming.
        </p>
        <p>
          While it might be cliche, there is no project too small, too large, or
          too complicated. My portfolio includes traditional brick-and-mortar
          businesses, such as hospitality and construction, and large
          corporations, such as the financial and procurement sector of the
          RCMP, where I handled extremely sensitive and time-sensitive data.
        </p>
        <p>
          <SpanBold>Specific roles include:</SpanBold>
        </p>
        <p>
        <ul>
          <li>Record and categorize financial transactions</li>
          <li>Bank and Credit Card Reconciliations</li>
          <li>Monthly, Annual or quarterly tax remittance</li>
          <li>
            Monthly financial statements and personalized advice based on the
            analytics of your business reports
          </li>
        </ul>
        </p>
      </ContentWrapper>

      <ColoredWrapper>
        <ContentWrapper>
          <SubHeading>Why Quickbooks Online?</SubHeading>
          <p>
            The right accounting software can make or break your small business
            books. Small and mid-sized businesses need a practical yet powerful
            accounting solution that won't break the bank. The benefit of
            Quickbooks Online is its flexible pricing packages for each
            business's unique needs while having the power to get all your
            finances together.
          </p>
        </ContentWrapper>
      </ColoredWrapper>

      <ContentWrapper>
        <div
          css={css`
            @media (min-width: 1140px) {
              display: grid;
              grid-template-columns: 1fr 1fr;
              justify-content: center;
              align-items: start;
            }
          `}
        >
          <div>
            <SubHeading>Quickbooks Online Setup</SubHeading>
            <p>
              While the initial setup of your books can be tricky and confusing,
              an expert like myself can ensure your books are set up correctly.
              I'll save you hundreds of hours in tutorials by helping you set up
              your Quickbooks Online. The other benefit? No installation or h2
              hardware space is required. You can access your books from any
              device with an internet browser and internet connection.
            </p>
          </div>
          <div>
            <SubHeading>Quickbooks Online Cleanup</SubHeading>
            <p>
              Sometimes what you need is a complete overhaul or cleanup of your
              books. I have experience cleaning up years' worth of transactions
              and getting your business back on track.
            </p>
            <p>
              <SpanBold>Cleanup Services Include:</SpanBold>
            </p>
            <p>
            <ul>
              <li>Reviewing your transactions for the year</li>
              <li>Cleaning up stray or duplicate transactions</li>
              <li>Customizing reports</li>
              <li>Balancing general ledger to subsidiary records</li>
              <li>Additional personalized training</li>
            </ul>
            </p>
          </div>
        </div>
      </ContentWrapper>
      <Spacer />
    </Layout>
  )
}

export default QuickbooksProAdvisorPage
